import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper/Wrapper';
import OrderConfirmationTemplate from '../components/Templates/OrderConfirmationTemplate';

const OrderConfirmationPage = ({ data }) => {
  const { title, confirmationMessage } = data.markdownRemark.frontmatter;

  return (
    <Wrapper>
      <OrderConfirmationTemplate title={title} content={confirmationMessage} />
    </Wrapper>
  );
};

OrderConfirmationPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default OrderConfirmationPage;

export const query = graphql`
  query OrderConfirmationQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "OrderConfirmation" } }) {
      frontmatter {
        title
        confirmationMessage
      }
    }
  }
`;
